import AccordionPage from './pages/AccordionPage';
import TypeMuseLogo from './typeMuse.png';

function App() {
  return (
    <div className='flex flex-col min-h-screen bg-slate-100 text-base'>
      <div className='flex flex-col flex-grow items-center justify-center p-4'>
        <div className='w-full max-w-4xl'>
          <div className='flex flex-col items-center mb-12 justify-center sm:flex-row sm:items-center sm:gap-4'>
            <img
              src={TypeMuseLogo}
              alt='TYPEMUSE'
              className='logo-img w-64 sm:w-80 md:w-96 lg:w-128 mb-4'
            />
            <span className='neon-text text-4xl sm:text-6xl md:text-8xl'>
              TYPEMUSE
            </span>
            <span className='text pt-8 text-blue-400 text-xl sm:text-xl md:text-xl'>
              Software architecture of a large scale distributed systems
            </span>
          </div>
          <AccordionPage />
        </div>
      </div>

      <footer className='w-full bg-gray-800 text-white text-center py-4'>
        <p className='text-sm sm:text-base'>
          &copy; 2024 TYPEMUSE LLC • A Syrosin Company
        </p>
      </footer>
    </div>
  );
}

export default App;

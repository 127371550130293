import { useState } from 'react';
import { GoChevronDown, GoChevronLeft } from 'react-icons/go';

function Accordion({ items }) {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleClick = (nextIndex) => {
    console.log('STALE version of expandedIndex', expandedIndex);

    setExpandedIndex((currentExpandedIndex) => {
      console.log('UP TO Date version', currentExpandedIndex);
      if (currentExpandedIndex === nextIndex) {
        return -1;
      } else {
        return nextIndex;
      }
    });
  };

  const renderedItems = items.map((item, index) => {
    const isExpanded = index === expandedIndex;
    console.log(isExpanded);

    const icon = (
      <span className='text-3xl text-orangered'>
        {isExpanded ? <GoChevronDown /> : <GoChevronLeft />}
      </span>
    );

    return (
      <div key={item.id}>
        <div
          className='flex justify-between p-3 bg-gray-50 border-b items-center cursor-pointer'
          onClick={() => handleClick(index)}
        >
          <span className='text-orangered text-2xl'>{item.label}</span>
          {icon}
        </div>
        <div>
          {isExpanded && (
            <div className='border-b p-5 text-xl text-gray-600'>
              {item.content}
            </div>
          )}
        </div>
      </div>
    );
  });

  return <div className='border-x border-t rounded'>{renderedItems}</div>;
}

export default Accordion;
